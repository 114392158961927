
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import { BIconFileEarmarkSpreadsheet } from 'bootstrap-vue';
import { CondOperator } from '@dataui/crud-request';
import ProductOrdersPendingModule from '@/store/product-orders-pending.module';
import ProductOrdersModule from '@/store/product-orders.module';
import { UserRoleEnum } from '@gid/models';

interface Filter {
  name: string;
  endPoint: string;
  searchFields: string[];
  queryParams?: Object;
}

@Component({
  components: {
    CrudMultiSelect,
    BIconFileEarmarkSpreadsheet,
  },
})
export default class ProductOrderFilters extends Vue {
  @Prop({ required: true }) store!:
    | ProductOrdersPendingModule
    | ProductOrdersModule;
  @Prop() readonly exportUrl!: string;
  filterTestJobs = false;

  @Watch('filterTestJobs')
  onFilterTestJobs(nextVal: boolean, prevVal: boolean) {
    if (prevVal !== nextVal) {
      this.$emit('handleIncludeFilterTestJobs', this.filterTestJobs);
    }
  }

  supplierQuery = {
    fields: ['id', 'name'],
    search: [
      {
        $or: [
          {
            role: { [CondOperator.EQUALS]: UserRoleEnum.PARTNER },
          },
          { role: { [CondOperator.EQUALS]: UserRoleEnum.BRAND } },
        ],
      },
    ],
  };

  get filterFields(): Filter[] {
    return [
      {
        name: 'Opportunity',
        endPoint: '/data-api/opportunity',
        searchFields: ['id', 'name'],
      },
      {
        name: 'Partner',
        endPoint: '/data-api/partner',
        searchFields: ['id', 'name'],
      },
      {
        name: 'Supplier',
        endPoint: '/data-api/account',
        searchFields: ['name'],
        queryParams: this.supplierQuery,
      },
      {
        name: 'Product',
        endPoint: '/data-api/product',
        searchFields: ['name'],
      },
    ];
  }
}
