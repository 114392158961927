
interface Product {
  productId: string;
  productName: string;
  supplierId: string;
  supplierName: string;
  quantity: number;
}

import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ProductsList extends Vue {
  @Prop() products!: Product[];

  get productsBySupplier() {
    const bySupplier = {};
    for (const product of this.products) {
      if (!(product.supplierId in bySupplier)) {
        bySupplier[product.supplierId] = {
          id: product.supplierId,
          name: product.supplierName,
          products: [],
        };
      }
      bySupplier[product.supplierId].products.push({
        id: product.productId,
        name: product.productName,
        quantity: product.quantity,
      });
    }
    return Object.values(bySupplier);
  }
}
